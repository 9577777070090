export const camelToPascal = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
/**
 * Helper function to convert an object to a query string.
 * ie. { colours: ["blue", "green"], sort: "alpha" } -> "?colours=blue,green&sort=alpha"
 * @param obj
 * @returns Formatted query string or empty string
 */
export const getParamStringFromObject = (
  obj?: Record<string, string | string[] | number | boolean | undefined>
): string => {
  const queryParams: string[] = [];

  if (!obj) {
    return "";
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        queryParams.push(`${key}=${value.join(",")}`);
      } else if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        queryParams.push(`${key}=${encodeURIComponent(value.toString())}`);
      }
    }
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  return queryString;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const isNullUndefinedOrEmpty = (value: any) => {
  if (
    value !== undefined &&
    value !== "" &&
    value !== "null" &&
    value !== null
  ) {
    return false;
  } else {
    return true;
  }
};

export const sortByKey = <T extends Object>(field: keyof T) => {
  return (a: T, b: T): number => {
    const A = typeof a[field] == "string" ? a[field].toUpperCase() : a[field];
    const B = typeof b[field] == "string" ? b[field].toUpperCase() : b[field];
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  };
};

export const roundTo = (num: number, length: number): number | string => {
  if (num == undefined) return "";
  const number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
  return number;
};

export const roundUpToQuarter = (num: number): number => {
  return Math.ceil(num * 4) / 4;
};

export const roundDownToQuarter = (num: number): number => {
  return Math.floor(num * 4) / 4;
};

/**
 * Converts a phone number from an unformatted value to properly formatted.
 *
 * @param str Phone number to format
 * @returns Properly formatted phone number
 */
export const formatPhoneNumber = (src: string) => {
  var cleaned = ("" + src).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const numberWithCommas = (x: number | string | undefined): string => {
  if (!x) return "";
  const num = typeof x === "string" ? parseFloat(x) : x;
  if (isNaN(num)) return "";
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const displayDate = (dateString: string | number) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

/**
 * Converts a string to proper title case.
 *
 * @param str The string to convert
 * @returns Converted string value
 */
export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function properCase(text: string | undefined): string {
  if (!text) {
    return "";
  }
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
